@font-face {
  font-family: 'museo';
  src: url('../assets/fonts/MuseoSans300.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'museo';
  src: url('../assets/fonts/MuseoSans500.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'museo';
  src: url('../assets/fonts/MuseoSans700.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

html,
body {
  font-family: 'museo';
}
